import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { UserDispatchContext } from "../../context/userProvider";
import { getOptimizedImages } from "../../../utilities/utili";
const variants = [
  { option: "Variant1", productId: 69 },
  { option: "Variant2", productId: 67 },
  { option: "Variant3", productId: 68 },
  { option: "Variant4", productId: 70 },
  { option: "Variant5", productId: 71 },
];

const Product = ({ product_categories_json, isMobile }) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [singleProductData, setSingleProductData] = useState();
  const setCart = useContext(UserDispatchContext);
  const [quantity, setQuantity] = useState(1);
  const [buttonState, setButtonState] = useState();
  const [buttonText, setButtonText] = useState();
  // const [variant, setVariant] = useState(variants);
  const [minCartItems, setMinCartItems] = useState("1");
  const cateringId =
    product_categories_json &&
    product_categories_json.find((data) => data.category === "Catering")
      .category_id;

  useEffect(() => {
    if (product_categories_json && slug) {
      let currentProduct;
      for (let i = 0; i < product_categories_json.length; i++) {
        for (let j = 0; j < product_categories_json[i].products.length; j++) {
          if (product_categories_json[i].products[j].slug === slug) {
            currentProduct = product_categories_json[i].products[j];
            break;
          }
        }
      }
      if (currentProduct) {
        if (currentProduct.product_category_id === cateringId) {
          setMinCartItems("10");
          setQuantity(10);
        } else {
          setMinCartItems("1");
        }
        setSingleProductData([currentProduct]);
        if (currentProduct.quantity_available === 0) {
          setButtonState("disabled");
          setButtonText("SOLD OUT");
        } else {
          setButtonState("");
          setButtonText("ADD TO CART");
        }
      } else {
        redirectToAllProducts();
      }
    } else {
      redirectToAllProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_categories_json, slug]);

  const redirectToAllProducts = () => {
    navigate("/products");
  };

  const perspect = window.perspect;
  const getStripeProductId = (product) => {
    let stripeProductId;
    if (perspect.site_env === "test") {
      stripeProductId = product.stripe_product_id_test;
    } else {
      stripeProductId = product.stripe_product_id_live;
    }
    return stripeProductId;
  };

  const regex = /(<([^>]+)>)/gi;

  const addProductToCart = (
    productStripeId,
    productName,
    productPrice,
    productImage,
    count,
    product_category_id
  ) => {
    let cartProducts =
      JSON.parse(localStorage.getItem("cartProducts-live")) || {};

    if (Object.keys(cartProducts).includes(productStripeId)) {
      const currentCount = cartProducts[productStripeId].count;
      const currentProduct = cartProducts[productStripeId];
      cartProducts = {
        ...cartProducts,
        [productStripeId]: {
          ...currentProduct,
          count: currentCount + count,
        },
      };
    } else {
      cartProducts = {
        ...cartProducts,
        [productStripeId]: {
          count: count,
          product: productName,
          productPrice,
          productImage,
          product_category_id,
        },
      };
    }
    localStorage.setItem("cartProducts-live", JSON.stringify(cartProducts));
    setCart(cartProducts);
  };

  const handleVarient = (e) => {
    return 0;
  };

  return (
    <div className="pt-20 pb-6 my-10 product-page">
      <div className="container mx-auto mt-18">
        <div className="grid lg:grid-cols-2 mg:grid-cols-2 sm:grid-cols-1 box-gap">
          <section>
            <div className="container mx-auto product-cards lg:px-10">
              <div className="product-box">
                <div className="relative">
                  <div
                    id="product"
                    className="carousel slide carousel-fade relative"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner relative w-full overflow-hidden">
                      {singleProductData &&
                        singleProductData.map((items) =>
                          getOptimizedImages(isMobile, items.media).map(
                            (productImgArray, idx) => {
                              // Rename to indicate it's an array
                              const productImg = productImgArray[0]; // Access the first element to get the actual image object
                              return (
                                <div
                                  key={idx}
                                  className={`${
                                    idx === 0
                                      ? "carousel-item active relative float-left w-full"
                                      : "carousel-item"
                                  } `}
                                >
                                  <img
                                    src={`https://${productImg.link}`} // Correctly accessing the link of the image object
                                    className="img-fluid w-100"
                                    alt=""
                                  />
                                </div>
                              );
                            }
                          )
                        )}
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#product"
                        data-bs-slide="prev"
                      >
                        <span aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#product"
                        data-bs-slide="next"
                      >
                        <span aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>

                    {/* <div className="carousel-indicators right-0 bottom-0 left-0 flex justify-center p-0 mb-4 mt-8">
                      {singleProductData &&
                        singleProductData.map((items) =>
                          items.media.map((productImg, idx) =>
                            idx <= 8 ? (
                              <button
                                key={idx}
                                type="button"
                                data-bs-target="#product"
                                data-bs-slide-to={idx}
                                className={`${idx === 0 ? "active" : ""}`}
                                aria-current="true"
                                aria-label={`Slide ${idx + 1}`}
                              >
                                <img
                                  src={`https://${productImg.link}`}
                                  className="img-fluid w-20"
                                  alt=""
                                />
                              </button>
                            ) : (
                              ""
                            )
                          )
                        )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {singleProductData &&
            singleProductData.map((items, idx) => (
              <div key={idx} className="pl-5">
                <h2 className="mb-2 text-[2rem]">{items.product}</h2>
                <div className="border-b py-2">
                  <span className="pricing font-light">${items.price}</span>
                </div>
                <div className="flex-container mt-3">
                  <p className="mr-2 font-semibold">QUANTITY</p>
                  <input
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    type="number"
                    min={minCartItems}
                    className="w-16 input-style"
                  />
                </div>
                {/* {singleProductData?.parent_variant && */}
                {/* <div className="mt-2">
                  <label>
                    <span>Select Variants</span>
                    <em>*</em>
                  </label>

                  <div className="flex-content flex justify-start ">

                    <select onChange={handleVarient} className=" input-style ">
                      {variant && variant.length && variant.map((variant, index) => {
                        return (
                          <option value={variant.option}>{variant.option}</option>
                        )
                      })}
                    </select>

                  </div>
                </div> */}
                {/* } */}

                <div className="mt-8 mb-5">
                  <button
                    type="button"
                    className="site-btn mr-3 w-60"
                    onClick={() =>
                      addProductToCart(
                        getStripeProductId(items),
                        items.product,
                        items.price,
                        getOptimizedImages(isMobile, items.media)[0][0].link,
                        parseInt(quantity),
                        items.product_category_id
                      )
                    }
                  >
                    {buttonText}
                  </button>
                </div>
                <div className="border-t py-4">
                  {/* <h6 className="mb-3">VIEW STORE INFORMATION</h6> */}
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{ __html: items.description }}
                  ></p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
