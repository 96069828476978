import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation

function SubscribeModal() {
  const showPopup = true;
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [csrfToken, setCsrfToken] = useState("");
  const location = useLocation();

  const isCookieExists = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`hidePopup=`))
    ?.split("=")[1];

  useEffect(() => {
    // Parse query params directly from window.location.search
    const queryParams = new URLSearchParams(window.location.search);
    const shouldShowModalBasedOnQueryParam = queryParams.has("subscribe");

    // Check the cookie only if the 'subscribe' query param is not present
    let shouldShowModal = shouldShowModalBasedOnQueryParam;
    if (!shouldShowModalBasedOnQueryParam) {
      // Here, check if the cookie exists
      // Assuming isCookieExists is a piece of state or a variable that reflects the cookie's presence
      const isCookieExists = document.cookie
        .split("; ")
        .some((row) => row.startsWith(`hidePopup=`));
      shouldShowModal = !isCookieExists;
    }

    // Set showModal based on the evaluation above
    setShowModal(shouldShowModal);

    // If you're creating a backdrop or modifying the body's style, do it here as needed
  }, []); // Ensure this effect is dependent on isCookieExists

  // useEffect(() => {
  //   if (!isCookieExists) {
  //     setTimeout(() => {
  //       setShowModal(true);
  //     }, 200);
  //     if (!document.getElementById("subscribe-backdrop") && showPopup) {
  //       const backdrop = document.createElement("div");
  //       backdrop.id = "subscribe-backdrop";
  //       backdrop.classList.add("subscribe-backdrop");
  //       document.body.appendChild(backdrop);
  //       document.body.style.overflow = "hidden";
  //     }
  //   }
  // }, [isCookieExists]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch("/csrf");
        const data = await response.json();
        setCsrfToken(data.csrf_token); // Assuming the token is in data.csrf_token
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleCloseModal = (isFormSubmitted) => {
    const backdropElement =
      document.getElementsByClassName("subscribe-backdrop");
    for (let i = 0; i < backdropElement.length; i++) {
      backdropElement[i].remove();
    }
    document.body.style.overflow = "auto";
    setShowModal(false);

    if (!isFormSubmitted) {
      const hours = 1;
      //setting cookie with expiry time of 1 hour
      let now = new Date();
      now.setTime(now.getTime() + hours * 3600 * 1000);
      document.cookie =
        "hidePopup=true; expires=" + now.toUTCString() + "; path=/";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData.entries());
    formProps.csrf_token = csrfToken;
    formProps.hp = "";

    try {
      const response = await fetch("/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formProps),
      });

      if (response.ok) {
        setSubmissionStatus("success");
        handleCloseModal();
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Form submission error:", error);
      setIsSubmitting(false);
      setSubmissionStatus("error");
    }
  };

  useEffect(() => {
    // Existing code to decide whether to show the modal or not...

    const handleClickOutside = (event) => {
      // Assuming your modal backdrop has an id of 'modal-backdrop'
      if (event.target.id === "modal-backdrop") {
        handleCloseModal();
      }
    };

    // Adding the click event listener to the backdrop
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`modal ${showModal && showPopup ? "show flex pl-0" : ""} fade`}
      aria-labelledby="subscribeModalTitle"
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog md:!w-[688px] !max-w-unset"
        style={{ maxWidth: "688px" }}
      >
        <div className="modal-content !rounded-none">
          <div className="modal-body relative flex p-0">
            <button
              type="button"
              className="btn-close absolute right-0 z-[999] !w-[43px] !h-[40px] bg-white bg-[length:23px]"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => handleCloseModal()}
            ></button>
            <div className="relative p-[20px] md:pr-[355px] md:pl-[30px] md:py-[30px] h-[521px] modal-box">
              <div className="md:block hidden">
                <img
                  src="https://depthngreen.com/215932336010404095567430799875557919/49EDAA97-CC65-42E2-867F-9E34D36BC2C2-500x571.JPG"
                  className="side-img"
                  alt="Depth N Green Cambridge, MA"
                />
              </div>
              <div>
                <h6 className="mb-5 text-[48px] leading-[1.25] relative !pb-0 main-h">
                  Now Open!
                </h6>
                <p className="text-[17px] leading-[1.4]">
                  Our cafe in Kendall Square in Cambridge, MA is now open. We're
                  located across the street from Locke Bar! We serve all natural
                  Indian cuisine crafted with love & care. Made only with
                  grassfed ghee, butter & olive oil for a deliciously healthy
                  meal.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group mt-10">
                    <label className="text-[17px] mb-1">Stay in touch</label>
                    <input
                      required
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="input-style mb-6"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="!mb-0 site-btn !w-full text-[17px]"
                  >
                    {isSubmitting ? (
                      <>
                        <svg
                          className="inline mr-2 -mt-1 h-5 w-5 animate-spin text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z"
                          />
                        </svg>
                        Submitting...
                      </>
                    ) : (
                      "Subscribe"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeModal;
