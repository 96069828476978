import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext, UserDispatchContext } from "../context/userProvider";
import "./styles.scss";
import { loadStripe } from "@stripe/stripe-js";
import { objToArr } from "../../utilities/utili";
import logo from "../../assets/img/Logo-DNG-.jpg";
const Header = ({ product_categories_json }) => {
  const [sticky, setSticky] = useState("");
  const location = useLocation();
  const pathName = location.pathname;
  const cart = useContext(UserContext);
  const setCart = useContext(UserDispatchContext);
  const [stateChange, setStateChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const cateringId =
    product_categories_json &&
    product_categories_json.find((data) => data.category === "Catering")
      .category_id;
  const deliveryData =
    product_categories_json &&
    product_categories_json.find((data) => data.category === "Delivery")
      .products[0];
  const [isDelivery, setIsDelivery] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 10 ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  function getProductsInCartToCheckout() {
    const perspect = window.perspect;
    var checkoutOrder = [];
    var productsInCart = JSON.parse(localStorage.getItem("cartProducts-live"));
    for (var key in productsInCart) {
      var item = {};
      item["stripe_product_id"] = key;
      item["quantity"] = productsInCart[key]["count"];
      checkoutOrder.push(item);
    }
    if (isDelivery) {
      const data = {
        quantity: 1,
        stripe_product_id:
          perspect.site_env === "test"
            ? deliveryData.stripe_product_id_test
            : deliveryData.stripe_product_id_live,
      };
      checkoutOrder.push(data);
    }
    return checkoutOrder;
  }

  async function checkout() {
    setIsLoading(true);
    let sk = "";
    const perspect = window.perspect;
    // console.log("perspect.site_env: ", perspect.site_env)
    if (perspect.site_env === "test") {
      // console.log("Using a TEST mode key")
      sk = perspect.spkt;
    } else {
      console.log("Using a LIVE mode key")
      sk = perspect.spkl;
    }

    let said = perspect.said;
    var stripe = await loadStripe(sk, {
      stripeAccount: said,
    });

    try {
      // No changes needed here for fetching CSRF as it sets a cookie automatically
      const csrfResponse = await fetch("/csrf");
      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrf_token; // Assuming you need the token value for something else

      const headers = new Headers({
        "Content-Type": "application/json",
      });

      // Make sure to include credentials if you are making requests to a different domain or if cookies won't be sent automatically
      fetch("https://depthngreen.com/checkout", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          products: getProductsInCartToCheckout(),
          csrf_token: csrfToken // Include CSRF token in the body as required
        }),
        credentials: 'include', // Ensure cookies are included with the request
      })
        .then(response => response.json())
        .then(session => {
          if (session.id) {
            setIsLoading(false);
          }
          return stripe.redirectToCheckout({ sessionId: session.id });
        })
        .catch(error => {
          setIsLoading(false);
          console.error("Error:", error);
        });

    } catch (error) {
      setIsLoading(false);
      console.error("Checkout error:", error);
    }
  }


  const handleQuantity = (type, productStripeId, productCategoryId) => {
    let cartProducts = cart;
    if (Object.keys(cartProducts).includes(productStripeId)) {
      const currentProduct = cartProducts[productStripeId];
      const count = cartProducts[productStripeId].count;
      if (type === "increment") {
        cartProducts = {
          ...cartProducts,
          [productStripeId]: {
            ...currentProduct,
            count: count + 1,
          },
        };
      } else if (productCategoryId === cateringId && count <= 10) {
        delete cartProducts[productStripeId];
      } else {
        let updatedCount = count - 1;
        if (updatedCount === 0) {
          delete cartProducts[productStripeId];
        } else {
          cartProducts = {
            ...cartProducts,
            [productStripeId]: {
              ...currentProduct,
              count: count - 1,
            },
          };
        }
      }
    }
    localStorage.setItem("cartProducts-live", JSON.stringify(cartProducts));
    setCart(cartProducts);
    setStateChange(!stateChange);
  };

  const handleRemoveProduct = (productStripeId) => {
    const cartProducts = cart;
    delete cartProducts[productStripeId];
    if (Object.keys(cartProducts).length === 0) {
      localStorage.removeItem("cartProducts-live");
    } else {
      localStorage.setItem("cartProducts-live", JSON.stringify(cartProducts));
    }
    setCart(cartProducts);
    setStateChange(!stateChange);
  };

  const getSubTotal = () => {
    let subTotal = 0,
      total = 0;
    for (const key in cart) {
      if (Object.hasOwnProperty.call(cart, key)) {
        const count = cart[key].count;
        const productPrice = cart[key].productPrice;
        const productCategoryId = cart[key].product_category_id;
        subTotal = subTotal + productPrice * count;

        if (
          productCategoryId === cateringId &&
          deliveryData &&
          deliveryData.price
        ) {
          total = subTotal + deliveryData.price;
        } else {
          total = subTotal;
        }
      }
    }

    return { subTotal, total };
  };

  const classes = `page-navbar navbar bg-white py-0 navbar-expand-lg navbar-light ${sticky}`;

  const getQuantity = () => {
    let quantity = 0;
    for (const key in cart) {
      if (Object.hasOwnProperty.call(cart, key)) {
        const count = cart[key].count;
        quantity = quantity + count;
      }
    }
    return quantity;
  };

  useEffect(() => {
    if (cart && Object.keys(cart).length) {
      let arr = objToArr(cart);
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].product_category_id === cateringId) {
          setIsDelivery(true);
          break;
        } else {
          setIsDelivery(false);
        }
      }
    } else {
      setIsDelivery(false);
    }
  }, [cart, stateChange]);

  return (
    <div className="header-content">
      <header>
        {location.pathname === "/checkout/test-success" ? null : (
          <div className="text-center top-nav-alert bg-secondary-clr py-3 text-white">
            <p className="font-extrabold">
              <a
                href="https://order.toasttab.com/online/depth-n-green-7-broad-canal-way/"
                className="text-white"
                style={{ textDecoration: "none", pointerEvents: "all" }}
              >
                NOW OPEN in CAMBRIDGE, MA! Cafe Menu for Dine-In or Quick & Easy Pickup!
              </a>
            </p>{" "}
          </div>
        )}
        <nav className={classes}>
          <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
            <button
              className="navbar-toggler mobile-view-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent1"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"

            >
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
            <Link to="/" className="text-xl pr-2">
              <img src={logo} alt="Depth 'N Green" className="h-20" />
            </Link>
            {location.pathname === "/checkout/test-success" ? null : (
              <div
                className="collapse navbar-collapse flex-grow items-center"
                id="navbarSupportedContent1"
              >
                <ul className="navbar-nav flex flex-col pl-0 list-style-none mx-auto">
                  <li className="nav-item p-2">
                    <Link
                      to="/"
                      className={`nav-link nav-menu ${pathName === "/" ? "active" : ""
                        }`}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item p-2">
                    <Link
                      to="/cafe"
                      className={`nav-link nav-menu ${pathName === "cafe" ? "active" : ""
                        }`}
                    >
                      Cafe
                    </Link>
                  </li>
                  <li className="nav-item p-2">
                    <a href="https://order.toasttab.com/online/depth-n-green-7-broad-canal-way/" className='nav-link nav-menu'>
                      Menu
                    </a>
                  </li>
                  <li className="nav-item p-2">
                    <Link
                      to="/catering"
                      className={`nav-link nav-menu ${pathName === "/catering" ||
                        pathName.includes("/catering")
                        ? "active"
                        : ""
                        }`}
                    >
                      Catering
                    </Link>
                  </li>
                  <li className="nav-item p-2">
                    <Link
                      to="/products"
                      className={`nav-link nav-menu ${pathName === "/products" ||
                        pathName.includes("/product")
                        ? "active"
                        : ""
                        }`}
                    >
                      Products
                    </Link>
                  </li>
                  <li className="nav-item p-2">
                    <Link
                      to="/aboutus"
                      className={`nav-link nav-menu ${pathName === "/aboutus" ? "active" : ""
                        }`}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item p-2">
                    <Link
                      to="/contact-us"
                      className={`nav-link nav-menu ${pathName === "/contact-us" ? "active" : ""
                        }`}
                    >
                      Contact Us
                    </Link>
                  </li>
                  {/* <li className="nav-item p-2">
                    <Link
                      to="/blogs"
                      className={`nav-link nav-menu ${
                        pathName === "/blogs" || pathName.includes("/blogs")
                          ? "active"
                          : ""
                      }`}
                    >
                      Blog
                    </Link>
                  </li> */}
                </ul>
              </div>
            )}

            <div
              className="flex-container mr-3 relative cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#CartModal"
            >
              <div className="relative h-cart mr-2">
                <i className="fa-solid fa-cart-arrow-down text-lg"></i>
                {Object.keys(cart).length ? (
                  <span className="cart-notification bg-primary-clr flex-container">
                    {getQuantity()}
                  </span>
                ) : null}
              </div>
              <span className="mr-2 font-bold">Cart</span>
            </div>
          </div>
        </nav>
      </header>

      {/* Cart view Modal */}
      <div
        className="modal fade"
        id="CartModal"
        aria-labelledby="CartModalTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog Abhi@1234">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close ml-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="font-semibold text-lg">Cart</div>
              <p className="item-pricing">{getQuantity()} Item(s)</p>
            </div>
            <div className="modal-body bg-white relative p-4 pt-2">
              {!!Object.keys(cart).length &&
                Object.keys(cart).map((key, index) => {
                  const product = cart[key];
                  return (
                    <div key={index} className="border-b">
                      <div className="flex justify-between pt-4 pb-2">
                        <div className="flex items-center">
                          <img
                            src={`https://${product.productImage}`}
                            className="img-fluid w-14 h-14 mr-3"
                            alt=""
                          />
                          <div>
                            <p>{product.product}</p>
                          </div>
                        </div>
                        <div>
                          <i
                            onClick={() => handleRemoveProduct(key)}
                            className="fa-solid fa-xmark cursor-pointer"
                          ></i>
                        </div>
                      </div>
                      <div className="flex-container justify-between pt-3 pb-5 item-pricing">
                        <p>${product.productPrice}</p>
                        <div className="quantity flex-container h-8">
                          <button
                            type="button"
                            className="mr-1"
                            aria-hidden="true"
                            onClick={() =>
                              handleQuantity(
                                "decrement",
                                key,
                                product.product_category_id
                              )
                            }
                          // disabled={
                          //   product.product_category_id === cateringId &&
                          //   product.count === 10
                          //     ? true
                          //     : false
                          // }
                          >
                            &minus;
                          </button>
                          <span className="flex items-center justify-center input-style item-quantity">
                            {product.count}
                          </span>
                          <button
                            type="button"
                            className="ml-1"
                            aria-hidden="true"
                            onClick={() => handleQuantity("increment", key)}
                          >
                            &#x2b;
                          </button>
                        </div>
                        <p>
                          $
                          {parseInt(product.productPrice) *
                            parseInt(product.count)}
                        </p>
                      </div>
                    </div>
                  );
                })}

              <div className="mt-12">
                <div className="flex justify-between mt-5 border-b py-2">
                  <p>Subtotal</p>
                  <p className="item-pricing">
                    ${getSubTotal().subTotal.toFixed(2)}
                  </p>
                </div>
                {isDelivery && (
                  <div className="flex justify-between mt-5 border-b py-2">
                    <p>Delivery Charges</p>
                    <p className="item-pricing">{`$${deliveryData && deliveryData.price
                      }`}</p>
                  </div>
                )}

                <div className="flex justify-between py-2">
                  <p>Total</p>
                  <p className="item-pricing">
                    ${getSubTotal().total.toFixed(2)}
                  </p>
                </div>
                {!!Object.keys(cart).length && (
                  <div className="text-right mt-6">
                    <button
                      disabled={isLoading}
                      type="button"
                      className="site-btn"
                      onClick={() => checkout()}
                    >
                      Checkout
                      {!!isLoading && (
                        <i className="fas fa-circle-notch ml-2 animate-spin"></i>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
