import twitter from "../../assets/img/x.svg"
import instragram from "../../assets/img/instagram.svg"
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container-fluid px-6 md:flex justify-between items-center text-center my-4">
        <p className="font-semibold">© {currentYear} Depth 'N Green.  <a className="font-semibold" href="https://perspect.com">
          Powered by Perspect Ecommerce
        </a></p>


        <div className="flex">
          <p className="font-semibold mr-4">7 Broad Canal Way, Cambridge, MA 02142</p>
          <a href="https://www.instagram.com/depthngreeninspiredfood/" target="_blank">
            <img className="mr-5" src={instragram} alt="instragram" />
          </a>
          <a href="https://twitter.com/depth_n_green" target="_blank">
            <img src={twitter} alt="X" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
